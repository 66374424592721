// src/pages/Document/DocumentsManagement.js

import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import './DocumentsManagement.css';
import { FaFilePdf, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const DocumentsManagement = () => {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const { data } = await axios.get('/api/documents');
      setDocuments(data);
    } catch (err) {
      setError('Belgeler yüklenirken bir hata oluştu.');
      console.error('Error fetching documents:', err);
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Bu belgeyi silmek istediğinizden emin misiniz?');
    if (!confirmed) return;

    try {
      await axios.delete(`/api/documents/${id}`);
      setDocuments((prevDocs) => prevDocs.filter((doc) => doc._id !== id));
    } catch (err) {
      setError('Belge silinirken bir hata oluştu.');
      console.error('Error deleting document:', err);
    }
  };

  const handleViewPdf = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <div className="documents-management-container">
      <h1>Belgeleri Yönet</h1>
      {error && <p className="message error">{error}</p>}
      {documents.length === 0 ? (
        <p className="no-documents-message">Yönetilecek belge bulunmamaktadır.</p>
      ) : (
        <div className="documents-grid">
          {documents.map(({ _id, name, logoUrl, pdfUrl }) => (
            <div key={_id} className="document-item">
              <div className="document-logo">
                <img
                  src={logoUrl}
                  alt={`${name} Logo`}
                  onError={(e) => {
                    e.target.src = '/images/fallback-logo.png';
                  }}
                />
              </div>
              <div className="document-name">
                <h3>{name}</h3>
              </div>
              <div className="document-actions">
                <button className="btn btn-view" onClick={() => handleViewPdf(pdfUrl)}>
                  <FaFilePdf /> PDF'i Görüntüle
                </button>
                <button className="btn btn-delete" onClick={() => handleDelete(_id)}>
                  <FaTrash /> Sil
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DocumentsManagement;
