// src/components/Contact.js

import React, { useState } from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot, faMobile} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import emailjs from 'emailjs-com'; // Import EmailJS

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    country: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      companyName: formData.companyName,
      email: formData.email,
      phone: formData.phone,
      country: formData.country,
      message: formData.message,
    };

    emailjs
      .send(
        'service_zvbdk3g', // Replace with your Service ID
        'template_o4uymov', // Replace with your Template ID
        templateParams,
        'NZQO_0MZ0BYGUPHtj' // Replace with your Public Key
      )
      .then(() => {
        setSuccessMessage('Mesajınız başarıyla gönderildi.');
        setErrorMessage('');
        setFormData({
          name: '',
          companyName: '',
          email: '',
          phone: '',
          country: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setErrorMessage('Mesaj gönderilirken bir hata oluştu.');
      });
  };

  return (
    <div className="contact-page">
      <div className="form-section">
        <h2 className="section-title">Bize Yazın</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleSubmit} className="contact-form">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="İsim Soyisim"
            required
          />
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            placeholder="Firma İsmi"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="E-mail"
            required
          />
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Telefon"
            required
          />
          
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Mesajınız"
            rows="5"
            required
          />
          <button type="submit" className="submit-button">
            Formu Gönder
          </button>
        </form>
      </div>

      <div className="contact-info-section">
        <h2 className="section-title">İletişime Geçin</h2>
        <div className="info-item">
          <FontAwesomeIcon icon={faLocationDot} className="info-icon" />
          <a
            href="https://maps.app.goo.gl/Jk8ef54a4V83cGyB8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kafkas Cd. No:5, 34953 Aydınlı KOSB Tuzla/İstanbul
          </a>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faPhone} className="info-icon" />
          <a href="tel:+902165049470">+90 216 504 94 70</a>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faPhone} className="info-icon" />
          <a href="tel:+902165049479">+90 216 504 94 79</a>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faMobile} className="info-icon" />
          <a href="tel:+905495754408">+90 549 575 44 08</a>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faEnvelope} className="info-icon" />
          <a href="mailto:info@krlkimya.com">info@krlkimya.com</a>
        </div>
        <div className="social-media">
          <FontAwesomeIcon
            icon={faFacebook}
            className="social-icon"
            onClick={() => window.open('https://www.facebook.com/krlkimya', '_blank')}
          />
          <FontAwesomeIcon
            icon={faInstagram}
            className="social-icon"
            onClick={() => window.open('https://www.instagram.com/krlkimya', '_blank')}
          />
          <FontAwesomeIcon
            icon={faLinkedin}
            className="social-icon"
            onClick={() => window.open('https://www.linkedin.com/company/krl-kimya', '_blank')}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;