// src/components/CategoryPage/CategoryPage.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from '../../utils/axiosConfig';
import './CategoryPage.css';

const CategoryPage = () => {
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const useQuery = () => new URLSearchParams(location.search);
  const category = useQuery().get('category');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/api/products', {
          headers: { 'Cache-Control': 'no-cache' },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  const filteredProducts = products.filter(
    (product) => product.category.toLowerCase() === category.toLowerCase()
  );

  const handleCardClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  /**
   * Helper function to get the 640w image URL from the images array.
   * It assumes that each image has a corresponding 640w version.
   * If no 640w image is found, it falls back to the first image.
   * @param {Array} images - Array of image URLs.
   * @returns {String} - URL of the 640w image or a fallback image.
   */
  const get640wImage = (images) => {
    // Since all images are now square, you can directly return the 640w image
    const image640w = images.find(url => url.includes('-640w.'));
    return image640w || images[0];
  };



  return (
    <Container className="unique-category-page mt-4">
      <Row className="g-4">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Col md={3} key={product._id}>
              <Card
                className="unique-category-card h-100"
                onClick={() => handleCardClick(product._id)}
                style={{ cursor: 'pointer' }}
              >
                {product.images && product.images.length > 0 && (
                  // Inside CategoryPage.js

                  <div className="unique-category-img-container">
                    <img
                      src={get640wImage(product.images)}
                      alt={product.title}
                      className="unique-category-img"
                      loading="lazy"
                    />
                  </div>

                )}
                <Card.Body className="d-flex flex-column unique-card-body">
                  <Card.Title className="unique-card-title">{product.title}</Card.Title>
                  <Card.Text className="unique-card-text">{product.description}</Card.Text>
                  {product.msds && (
                    <a
                      href={product.msds} // Ensure msds URL has Content-Disposition set to inline
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary unique-btn-download mt-auto"
                    >
                      MSDS İNDİR
                    </a>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p className="unique-no-products">Bu kategoriye ait ürün bulunamadı.</p>
        )}
      </Row>
    </Container>
  );
};

export default CategoryPage;
