// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from 'react-router-dom';
import App from './App';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css'; // Your existing styles



ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
  <App/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
