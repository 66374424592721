// src/pages/ProductManagement/ProductManagement.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductManagement.css';
import { Link } from 'react-router-dom';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faExclamationTriangle, faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState({ type: '', message: '' });
  const [searchQuery, setSearchQuery] = useState('');
  const [sortKey, setSortKey] = useState('title');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('http://167.99.138.77:3001/api/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
      setFeedbackMessage({ type: 'danger', message: 'Ürünler alınırken bir hata oluştu.' });
    }
  };

  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!productToDelete) return;

    try {
      await axios.delete(`http://167.99.138.77:3001/api/products/${productToDelete._id}`);
      setProducts(products.filter((p) => p._id !== productToDelete._id));
      setFeedbackMessage({ type: 'success', message: `${productToDelete.title} başarıyla silindi.` });
    } catch (error) {
      console.error('Error deleting product:', error);
      setFeedbackMessage({ type: 'danger', message: 'Ürün silinirken bir hata oluştu. Lütfen tekrar deneyin.' });
    } finally {
      setShowConfirmModal(false);
      setProductToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
    setProductToDelete(null);
  };

  // Search Functionality
  const filteredProducts = products.filter(
    (product) =>
      product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sorting Functionality
  const sortedProducts = React.useMemo(() => {
    let sortableProducts = [...filteredProducts];
    if (sortKey !== null) {
      sortableProducts.sort((a, b) => {
        if (a[sortKey] < b[sortKey]) {
          return -1;
        }
        if (a[sortKey] > b[sortKey]) {
          return 1;
        }
        return 0;
      });
    }
    return sortableProducts;
  }, [filteredProducts, sortKey]);

  // Pagination Logic
  const pageCount = Math.ceil(sortedProducts.length / itemsPerPage);
  const displayProducts = sortedProducts.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  return (
    <div className="product-management">
      <h1>Ürün Yönetimi</h1>

      {/* Add Product Button */}
      <div className="add-product-button-container">
        <Link to="/add-product" className="add-product-button">
          Yeni Ürün Ekle
        </Link>
      </div>

      {/* Feedback Message */}
      {feedbackMessage.message && (
        <div
          className={`alert ${
            feedbackMessage.type === 'success' ? 'alert-success' : 'alert-danger'
          }`}
          role="alert"
        >
          {feedbackMessage.message}
        </div>
      )}

      {/* Search and Sort Controls */}
      <Form className="mb-4">
        <Row>
          <Col md={6} className="mb-2">
            <Form.Control
              type="text"
              placeholder="Ürün adı veya kategori ile ara..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search Products"
            />
          </Col>
          <Col md={6} className="mb-2">
            <Form.Select
              value={sortKey}
              onChange={(e) => setSortKey(e.target.value)}
              aria-label="Sort Products"
            >
              <option value="title">Ürün Adına Göre Sırala</option>
              <option value="dateAdded">Eklenme Tarihine Göre Sırala</option>
              {/* Add more sort options as needed */}
            </Form.Select>
          </Col>
        </Row>
      </Form>

      {/* Product List */}
      {displayProducts.length === 0 ? (
        <p>Hiç ürün bulunmamaktadır.</p>
      ) : (
        <>
          <ul className="product-list">
            {displayProducts.map((product) => (
              <li key={product._id} className="product-item">
                <span className="product-title">{product.title}</span>
                <div className="product-actions">
                  <button
                    onClick={() => handleDeleteClick(product)}
                    className="product-delete-btn"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} /> Sil
                  </button>
                </div>
              </li>
            ))}
          </ul>

          {/* Pagination Controls */}
          {pageCount > 1 && (
            <ReactPaginate
              previousLabel={'← Önceki'}
              nextLabel={'Sonraki →'}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          )}
        </>
      )}

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCancelDelete} centered className="confirmation-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faExclamationTriangle} color="#ffc107" /> Ürünü Sil
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {productToDelete && (
            <p>
              <strong>{productToDelete.title}</strong> adlı ürünü silmek istediğinize emin misiniz?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            İptal
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Sil
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProductManagement;
