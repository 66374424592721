// src/components/Catalog.js

import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import * as pdfjsLib from 'pdfjs-dist';
import { FaDownload } from 'react-icons/fa';
import './Catalog.css';

// Set the workerSrc property for pdfjs
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const Catalog = () => {
  const [catalogs, setCatalogs] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch catalogs on mount
    const fetchCatalogs = async () => {
      try {
        const { data } = await axios.get('/api/catalogs');

        // Process the data to ensure pdfUrl includes 'https://'
        const processedCatalogs = data.map((catalog) => {
          let { pdfUrl } = catalog;
          if (!pdfUrl.startsWith('http')) {
            pdfUrl = `https://${pdfUrl}`;
          }
          return { ...catalog, pdfUrl };
        });

        setCatalogs(processedCatalogs);
      } catch (err) {
        setError('Error fetching catalogs.');
        console.error('Error fetching catalogs:', err);
      }
    };
    fetchCatalogs();
  }, []);

  const handleOpenPdf = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <div className="catalog-page">
      <h1>Kataloglarımız</h1>
      {error && <p className="error-message">{error}</p>}
      <div className="catalogs-grid">
        {catalogs.map(({ _id, name, pdfUrl }) => (
          <CatalogCard
            key={_id}
            name={name}
            pdfUrl={pdfUrl}
            onOpenPdf={handleOpenPdf}
          />
        ))}
      </div>
    </div>
  );
};

const CatalogCard = ({ name, pdfUrl, onOpenPdf }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    const loadThumbnail = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1); // Pages are 1-based

        const viewport = page.getViewport({ scale: 1.5 }); // Adjust scale as needed
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        const thumbnailDataUrl = canvas.toDataURL();
        setThumbnailUrl(thumbnailDataUrl);
      } catch (error) {
        console.error('Error generating thumbnail:', error);
      }
    };

    loadThumbnail();
  }, [pdfUrl]);

  return (
    <div className="catalog-card">
      <div className="card-header">
        <h3 className="catalog-name" title={name}>
          {name}
        </h3>
      </div>
      <div className="pdf-preview" onClick={() => onOpenPdf(pdfUrl)}>
        {thumbnailUrl ? (
          <img src={thumbnailUrl} alt={`Preview of ${name}`} />
        ) : (
          <div className="loading-placeholder">Loading preview...</div>
        )}
      </div>
      <a
        href={pdfUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="download-pdf-btn"
      >
        <FaDownload /> İndir
      </a>
    </div>
  );
};

export default Catalog;