export let products = [
    
    {
      id:'kc-001',
      title: 'KC 001 EQUINOX',
      category: 'mutfak',
      description: ['Hijyenik Elde Bulaşık Yıkama Deterjanı'],
      content:  ['Elde bulaşık yıkamada kullanılır, endüstriyel kullanıma uygundur.',
                  'Güçlü formülü ile bulaşıklarda ki en zorlu yağ ve kirleri kolayca söker.',
                  'Yüzey aktif madde miktarı yüksek, köpüğü bol ve kalıcıdır.',
                  "Cilt pH'na uygundur; etkili parfümü ile hoş kokuludur.",
                  "pH : 6-7"
                ],
      weight: ['20 kg','5 kg','1 lt'],
      packSize:['1  x 20 kg','4 x 5 kg','6 x 1 lt'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.84 EQUINOX.pdf'
    },

    {
      id:'kc-002',
      title: 'KC 002 SUN(LEMON)',
      category: 'mutfak',
      description: ['Konsantre Elde Bulaşık Yıkama Deterjanı'],
      content:  ['Elde bulaşık yıkamada kullanılır, endüstriyel kullanıma uygundur.',
                  'Güçlü formülü ile bulaşıklarda ki en zorlu yağ ve kirleri kolayca söker',
                  'Köpüğü kalıcıdır.',
                  "Cildin pH'ına uygundur.",
                  'Hoş kokuludur.',
                  'pH : 6-7',
                ],
      weight: ['20 kg','5kg'],
      packSize:['1  x 20 kg','4 x 5 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.01 SUN.pdf'
      
    },
    {
      id: 'kc-002-apple',
      title: 'KC 002 SUN (APPLE)',
      category: 'mutfak',
      description: ['Konsantre Elde Bulaşık Yıkama Deterjanı'],
      content: ['Elde bulaşık yıkamada kullanılır, endüstriyel kullanıma uygundur.',
                'Güçlü formül ile bulaşıklarda ki en zorlu yağ ve kirleri kolayca söker.',
                'Köpüğü bol ve kalicidir.',
                "Cildin pH'ına uygundur.",
                'Hoş kokuludur.',
                'pH : 6-7',
              ],
      weight: ['20 kg','5kg'],
      packSize:['1  x 20 kg','4 x 5 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.01 SUN.pdf'

    },
    {
      id:'kc-003',
      title: 'KC 003 MATIK',
      category: 'mutfak',
      description: ['Endüstriyel Bulaşık Makinesi Deterjanı'],
      content:  ['Endüstriyel tip bulaşık makinelerinde özel dozaj ekipmanları ile kullanılır.',
                  'Özel formülü ile sert suların bulaşık makinelerinde oluşturduğu kireçlenmeyi engeller.',
                  'Özellikle cam, porselen gibi hassas materyallerde su lekelerinin oluşmasını engeller.',
                  "Özel formülü sayesinde yağ, nişasta, protein gibi zor lekeleri kolay ve hızlı temizler.",
                  'pH : 12-13',
                ],
      weight: ['23 kg','5.75 kg'],
      packSize:['1  x 23 kg','4 x 5.75 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.02 EQUINOX MATİK.pdf'
      
    },
    {
      id:'kc-004',
      title: 'KC 004 MATIK GOLD',
      category: 'mutfak',
      description : ['Endüstriyel Bulaşık Makinesi Deterjanı','Hassas yüzeyler için'],
      content:  ['Endüstriyel tip bulaşık makinelerinde özel dozaj ekipmanları ile kullanılır.',
                  'Özel formülü ile sert suların bulaşık makinelerinde oluşturduğu kireçlenmeyi engeller.',
                  'Özellikle cam, porselen gibi hassas materyallerde su lekelerinin oluşmasını engeller.',
                  "Özel formülü sayesinde yağ, nişasta, protein gibi zor lekeleri kolay ve hızlı temizler.",
                  'pH : 12-13',
                ],
      weight: ['25 kg','12.5 kg'],
      packSize:['1  x 25 kg','1 x 12.5 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.77 EQUINOX MATİK GOLD.pd'


    },
    {
      id:'kc-005',
      title: 'KC 005 RINS',
      category: 'mutfak',
      description: ['Endüstriyel Bulaşık Makinesi Deterjanı','Bardaklar için'],
      content:  [ 'Endüstriyel tip bulaşık makinelerinde özel dozaj ekipmanlar ile kullanılır.',
                  "Uygun pH'ı sayesinde deterjan kalıntılarının durulanmasını sağlar.",
                  'Kirin; temizlenmiş yüzeye tekrar tutunmasını engeller.',
                  '*Özel formülü sayesinde cam, porselen gibi hassas yüzeylerin hızlı ve lekesiz kurumasını sağlar.',
                  'pH : 2-3',
                ],
      weight: ['20 kg','5 kg'],
      packSize:['1  x 20 kg','4 x 5 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.03 RİNS.pdf'
      
    },
    {
      id:'kc-006',
      title: 'KC 006 RINS GOLD',
      category: 'mutfak',
      description: ['Endüstriyel Bulaşık Makinesi Durulama Maddesi'],
      content:  [ 'Endüstriyel tip bulaşık makinelerinde özel dozaj ekipmanlar ile kullanılır.',
                  "Uygun pH'ı sayesinde deterjan kalıntılarının durulanmasını sağlar.",
                  'Kirin; temizlenmiş yüzeye tekrar tutunmasını engeller.',
                  '*Özel formülü sayesinde cam, porselen, alüminyum, bakır gibi hassas yüzeylerin hızlı ve lekesiz kurumasını sağlar.',
                  'pH : 2-3',
                ],
      weight: ['20 kg','5 kg'],
      packSize:['1  x 20 kg','1 x 10 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.74 RİNS GOLD.pdf'
      
    },
    {
      id:'kc-010',
      title: 'KC 010 ANTILIME',
      category: 'mutfak',
      description: ['Endüstriyel Bulaşık Makinesi Kireç Çözücü'],
      content:  [ 'Enerji kaybına yol açan, makinelerin ömrünü kısaltan kirecin sökülmesinde kullanılır.',
                  'Düzenli kullanımlarda kireç oluşmasını önler. Böylece ekipmanlar daha verimli çalışır.',
                  'Rezistanslarda, boru hattında ve sprey uçlarında oluşan tıkanıkları ortadan kaldırarak yıkama kalitesini arttırır.',
                  'pH : 0-1',
                ],
      weight: ['20 kg','5 kg'],
      packSize:['1  x 20 kg','4 x 5 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.04 EQUINOX ANTILIME.pdf'
      
    },
    {
      id:'kc-012',
      title: 'KC 012 PREMIER',
      category: 'mutfak',
      description: ['Leke Çıkarıcı Ön Daldırma Maddesi'],
      content:  [ 'Yıkama sırasında temizlenmeyen inatçı kirler için formüle edilmiştir. Kurumuş, nişasta, protein, yağ gibi lekeleri kolayca temizler.',
                  'İçeriğindeki klor sayesinde hijyen sağlar.',
                  'Özellikle çay ve kahve lekelerinde etkilidir.',
                  'Özel dozaj ekipmanları ile endüstriyel bulaşık makinelerinde de kullanılır',
                  'pH : 12-13',
                ],
      weight: ['20 kg','5 kg'],
      packSize:['1  x 20 kg','4 x 5 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.05 PREMIER.pdf'
      
    },

    {
      id:'kc-009',
      title: 'KC 009 IRIS',
      category: 'mutfak',
      description: ['Jel Bulaşık Makinesi Deterjanı'],
      content:  [ 'Bulaşık makinelerinde kullanılan; hızlı çözünen jel formülü ile parlatıcıya gerek kalmaz.',
                  'Mükemmel parlaklık ve temizliğe kavuşmanızı sağlar.',
                  'CAm koruma gücü sayesinde; bulaşıklarınızı toz deterjanların sebep olduğu cam aşınmasına karşı korur.',
                  'Bardaklarınız her yıkamada korunu, çizilemez ve daha uzun süre parlaklık sağlar.',
                  'pH : 8-9',
                ],
      weight: ['1 lt'],
      packSize:['6  x 1 lt'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.05 PREMIER.pdf'
      
    },

    {
      id:'kc-011',
      title: 'KC 011 HUNTER POWDER',
      category: 'mutfak',
      description: ['Bulaşık Makineleri için Kir ve Tortu Sökücü Yardımcı Yıkama Maddesi'],
      content:  [ 'Kurumuş çay,kahve, meyve suyu, ruj ve tanin lekelerinin çıkarılmasında etkilidir.',
                  'Metal ekipmanları parlatır ve protein lekelerini etkili şekilde temizler.',
                  'pH : 12-13',
                ],
      weight: ['1 kg'],
      packSize:['9  x 1 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.325 HUNTER POWDER.pdf'
      
    },

    {
      id:'kc-026',
      title: 'KC 026 SCAN POWER',
      category: 'mutfak',
      description: ['Porselenden Metal Çizik Giderici'],
      content:  [ 'Porselen tabakalarda zamanla oluşna; standart yıkama ve ağartma maddeleri ile çıkarılmayan çatal kaşık çiziklerini daldırma yöntemiyle giderir.',
                  'Endüstriyel kullanım içindir',
                  'Porselendeki cila ve sır tabakasına zarar vermez.',
                  'pH : 0-1',
                ],
      weight: ['5 kg'],
      packSize:['4  x 5 kg'],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.16 SCAN POWER.pdf'
      
    },
    
    {
      id:'kc-016',
      title: 'KC 016 GRILL COLD',
      category: 'mutfak',
      description: ['Soğuk Yüzey Yağ ve Karbon Sökücü'],
      content:  [ 'Ocak, finn, izgara, fritöz ve davlumbaz gibi mutfak ekipmanlandaki agir yagları, isitmaya gerek kalmadan kolayca temizier.',
                  'Yogun ve kalici köpügü sayesinde dik yüzeylere kolayca tutunarak yağı daha kısa sürede söker. ',
                  'Özel formülü sayesinde ovma ve kazıma gerektirmez; zamandan ve iscilikten tasaruf saglar.',
                  'pH : 13-14',
                ],
      weight: ['20 kg','5 kg', '1lt'],
      packSize:['1  x 20 kg ',' 4 x 5 kg ','6 x 1 lt' ],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.07 GRILL COLD.pdf'
      
    },

    {
      id:'kc-017',
      title: 'KC 017 GRILL HEAVY',
      category: 'mutfak',
      description: ['Sıcak Yüzey Yağ ve Karbon Sökücü'],
      content:  [ 'Ağır ve karbonlaşmış yağların temizliğinde kullanılan köpük bir sıvıdır.',
                  'Ocak, ızgara, fırın, grill, fritöz gibi mutfak ekipmanlarında oluşan ağır yağları kolaylıkla temizler.',
                  'Sıcak yüzeyler daha etkili sonuç verir.',
                  'pH : 13-14',
                ],
      weight: ['20 kg',  '5 kg', '1lt'],
      packSize:['1  x 20 kg ',' 4 x 5 kg ','6 x 1 lt' ],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.301 GRILL HEAVY.pdf'
      
    },

    {
      id:'kc-014',
      title: 'KC 014 WEAY',
      category: 'mutfak',
      description: ['Ağır Kir ve Yağ Sökücü', 'Daldırma İçin Uygun'],
      content:  [ 'Mutfak ekipmanlarında oluşan karbonlaşmış yağların daldırma yöntemi ile temizlenmesi için formüle edilmiştir.',
                  'Fırın tepsileri, kızartma, tavalarında oluşan karbonlaşmış yağları kolayca temizler.',
                  'Alüminyum yüzeyler için uygun değildir.',
                  'pH : 13-14',
                ],
      weight: ['20 kg',  '5 kg'],
      packSize:['1  x 20 kg ',' 4 x 5 kg '],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.06 WEAY.pdf'
      
    },
    
    {
      id:'kc-015',
      title: 'KC 015 GRILL',
      category: 'mutfak',
      description: ['Köpüklü Yanık Yağ ve Kir Çözücü'],
      content:  [ 'Ağır ve karbonlaşmış yağların temizliğinde kullanılan köpük bir sıvıdır.',
                  'Ocak, ızgara, fırın, grill, fritöz gibi mutfak ekipmanlarında oluşan ağır yağları kolaylıkla temizler.',
                  'Sıcak yüzeyler daha etkili sonuç verir.',
                  'pH : 12-13',
                ],
      weight: ['20 kg',  '5 kg'],
      packSize:['1  x 20 kg ',' 4 x 5 kg '],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.73 EQUINOX  GRILL.pdf'
      
    },

    {
      id:'kc-018',
      title: 'KC 018 EQU',
      category: 'mutfak',
      description: ['Yağ ve Kir Sökücü Köpüklü Sıvı'],
      content:  [ 'Ağır yağ ve kirlerin çıkarılmasında kullanılan bol köpüklü bir üründür.',
                  'Gıda üretim tesisindeki günlük yağ ve kirlerin temizliğinde etkilidir.',
                  'Zeminlerde, çalışma tezgahlarında, davlumbazlarda, et ve tavuk kesimhanelerinde termobox ve kasalarda oluşan yağ ve kirlerin temizliğinde etkin sonuç verir.',                  
                  'pH : 12-13',
                ],
      weight: ['20 kg',  '5 kg'],
      packSize:['1  x 20 kg ',' 4 x 5 kg '],
      imageUrl: '/images/urun.webp',
      pdfUrl: '/Equinox_Mutfak_MSDS/GBF.73 EQUINOX  GRILL.pdf'
      
    },
    

    
    // ...add more products as needed
  ];
