// src/components/ProductManagement/ProductForm.js

import React, { useState } from 'react';
import axios from 'axios';
import './ProductForm.css';
import { Form, Button, Alert, Row, Col, Image, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrashAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

const ProductForm = () => {
  // Form State
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([null, null, null, null]); // 4 Images
  const [imagePreviews, setImagePreviews] = useState([null, null, null, null]);
  const [msds, setMsds] = useState(null);
  const [tds, setTds] = useState(null);
  const [content, setContent] = useState('');
  const [weight, setWeight] = useState('');
  const [packSize, setPackSize] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');

  // Feedback State
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Modal State
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState('');

  // Handle Image Selection and Preview
  const handleImageChange = (index, file) => {
    const newImages = [...images];
    newImages[index] = file;
    setImages(newImages);

    if (file) {
      const newPreviews = [...imagePreviews];
      newPreviews[index] = URL.createObjectURL(file);
      setImagePreviews(newPreviews);
    } else {
      const newPreviews = [...imagePreviews];
      newPreviews[index] = null;
      setImagePreviews(newPreviews);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    // Basic Form Validation
    if (!category || !title || !description || !images[0] || !msds) {
      setErrorMessage('Lütfen tüm gerekli alanları doldurun.');
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append('category', category);
    formData.append('title', title);
    formData.append('description', description);

    images.forEach((image, index) => {
      if (image) {
        formData.append('images', image); // Ensure 'images' is used here
      }
    });

    formData.append('msds', msds);

    if (tds) {
      formData.append('tds', tds);
    }

    formData.append('content', content);
    formData.append('weight', weight);
    formData.append('packSize', packSize);
    formData.append('youtubeLink', youtubeLink);

    try {
      const response = await axios.post('/api/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setSuccessMessage('Ürün başarıyla eklendi!');
      setErrorMessage('');
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top on error

      // Reset Form
      setCategory('');
      setTitle('');
      setDescription('');
      setImages([null, null, null, null]);
      setImagePreviews([null, null, null, null]);
      setMsds(null);
      setTds(null);
      setContent('');
      setWeight('');
      setPackSize('');
      setYoutubeLink('');
    } catch (error) {
      console.error('Error adding product:', error.message, error.response?.data);
      setErrorMessage('Ürün eklenirken bir hata oluştu. Lütfen tekrar deneyin.');
      setSuccessMessage('');
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top on error
    }
    setIsSubmitting(false);
  };

  // Handle Modal Image Display
  const handleImageClick = (preview) => {
    setModalImage(preview);
    setShowModal(true);
  };

  return (
    <div className="product-management">
      <h1>Ürün Ekle</h1>

      {/* Feedback Messages */}
      {successMessage && (
        <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
          {errorMessage}
        </Alert>
      )}

      <Form className="product-form" onSubmit={handleSubmit} method="POST">
        {/* Category Selection */}
        <Form.Group as={Row} className="mb-3" controlId="formCategory">
          <Form.Label column sm={3}>
            Kategori <span className="required">*</span>
          </Form.Label>
          <Col sm={9}>
            <Form.Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
              aria-describedby="categoryHelp"
            >
              <option value="">Kategori Seçin</option>
              <option value="mutfak">Mutfak</option>
              <option value="bina-bakimi">Bina Bakımı</option>
              <option value="kisisel-hijyen">Kişisel Hijyen</option>
              <option value="camasir">Çamaşır</option>
              <option value="gida-hijyeni">Gıda Hijyeni</option>
              <option value="havuz-kimyasallari">Havuz Kimyasalları</option>
              <option value="ozel-urunler">Özel Ürünler</option>
              <option value="endustriyel-kimya">Endüstriyel Kimyasallar</option>
            </Form.Select>
            <Form.Text id="categoryHelp" muted>
              Ürünün kategorisini seçin.
            </Form.Text>
          </Col>
        </Form.Group>

        {/* Product Title */}
        <Form.Group as={Row} className="mb-3" controlId="formTitle">
          <Form.Label column sm={3}>
            Ürün Adı <span className="required">*</span>
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              placeholder="Ürün adını girin"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Col>
        </Form.Group>

        {/* Product Description */}
        <Form.Group as={Row} className="mb-3" controlId="formDescription">
          <Form.Label column sm={3}>
            Ürün Açıklaması <span className="required">*</span>
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Ürün açıklamasını girin"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Col>
        </Form.Group>

        {/* Product Images */}
        <Form.Group className="mb-3">
          <Form.Label>Ürün Görselleri <span className="required">*</span></Form.Label>
          <Row>
            {[0, 1, 2, 3].map((index) => (
              <Col key={index} sm={6} md={3} className="mb-3">
                <div className="image-upload-wrapper">
                  <div
                    className="image-upload dropzone"
                    onClick={() => document.getElementById(`imageInput${index}`).click()}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      const file = e.dataTransfer.files[0];
                      handleImageChange(index, file);
                    }}
                  >
                    {imagePreviews[index] ? (
                      <Image
                        src={imagePreviews[index]}
                        thumbnail
                        onClick={() => handleImageClick(imagePreviews[index])}
                        className="uploaded-image"
                        alt={`Ürün Görseli ${index + 1}`}
                        loading="lazy"
                      />
                    ) : (
                      <div className="upload-placeholder">
                        <FontAwesomeIcon icon={faUpload} size="2x" />
                        <p>Görsel {index + 1}</p>
                      </div>
                    )}
                  </div>
                  <Form.Control
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={(e) => handleImageChange(index, e.target.files[0])}
                    id={`imageInput${index}`}
                    hidden
                  />
                  {imagePreviews[index] && (
                    <Button
                      variant="danger"
                      size="sm"
                      className="mt-2 delete-btn"
                      onClick={() => handleImageChange(index, null)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} /> Sil
                    </Button>
                  )}
                  {index === 3 && (
                    <Form.Text className="text-muted">
                      Tanıtım Görseli olarak kullanılacak.
                    </Form.Text>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Form.Group>

        {/* MSDS PDF */}
        <Form.Group as={Row} className="mb-3" controlId="formMSDS">
          <Form.Label column sm={3}>
            MSDS (PDF) <span className="required">*</span>
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="file"
              accept="application/pdf"
              onChange={(e) => setMsds(e.target.files[0])}
              required
            />
            <Form.Text className="text-muted">
              MSDS dosyasını yükleyin.
            </Form.Text>
          </Col>
        </Form.Group>

        {/* TDS PDF */}
        <Form.Group as={Row} className="mb-3" controlId="formTDS">
          <Form.Label column sm={3}>
            TDS (PDF)
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="file"
              accept="application/pdf"
              onChange={(e) => setTds(e.target.files[0])}
            />
            <Form.Text className="text-muted">
              TDS dosyasını yükleyin (isteğe bağlı).
            </Form.Text>
          </Col>
        </Form.Group>

        {/* Product Details */}
        <Form.Group as={Row} className="mb-3" controlId="formContent">
          <Form.Label column sm={3}>
            Ürün Detayları
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Ürün detaylarını girin (her satır yeni bir detay)"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <Form.Text className="text-muted">
              Detayları her satıra bir detay olacak şekilde yazın.
            </Form.Text>
          </Col>
        </Form.Group>

        {/* Product Weights */}
        <Form.Group as={Row} className="mb-3" controlId="formWeight">
          <Form.Label column sm={3}>
            Ürün Ağırlıkları
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Ürün ağırlıklarını girin (her satır yeni bir ağırlık)"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
            <Form.Text className="text-muted">
              Ağırlıkları her satıra bir ağırlık olacak şekilde yazın.
            </Form.Text>
          </Col>
        </Form.Group>

        {/* Package Sizes */}
        <Form.Group as={Row} className="mb-3" controlId="formPackSize">
          <Form.Label column sm={3}>
            Paket Boyutları
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Paket boyutlarını girin (her satır yeni bir boyut)"
              value={packSize}
              onChange={(e) => setPackSize(e.target.value)}
            />
            <Form.Text className="text-muted">
              Boyutları her satıra bir boyut olacak şekilde yazın.
            </Form.Text>
          </Col>
        </Form.Group>

        {/* YouTube Link */}
        <Form.Group as={Row} className="mb-3" controlId="formYouTube">
          <Form.Label column sm={3}>
            YouTube Linki
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="url"
              placeholder="YouTube video linkini girin"
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
            />
            <Form.Text className="text-muted">
              Ürününüzle ilgili YouTube videosu ekleyebilirsiniz.
            </Form.Text>
          </Col>
        </Form.Group>

        {/* Submit Button */}
        <div className="d-flex justify-content-end">
          <Button variant="primary" type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
                Yükleniyor...
              </>
            ) : (
              'Ürün Ekle'
            )}
          </Button>
        </div>
      </Form>

      {/* Image Preview Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
        <Modal.Body className="d-flex justify-content-center">
          <Image src={modalImage} alt="Image Preview" fluid />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductForm;
