// src/pages/Login.js

import React, { useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosConfig'; // Ensure the correct path
import { AuthContext } from '../../authContetxt'; // Corrected typo in 'authContext'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import './Login.css'; // Ensure this path is correct

const Login = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const { setAuthToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to map English error messages to Turkish
  const mapErrorMessage = (message) => {
    const errorMap = {
      'Invalid username or password': 'Geçersiz kullanıcı adı veya şifre.',
      'User not found': 'Kullanıcı bulunamadı.',
      'Account locked': 'Hesap kilitlendi.',
      // Add more mappings as needed
    };

    return errorMap[message] || 'Giriş sırasında beklenmeyen bir hata oluştu.';
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true); // Start loading
    try {
      console.log("Gönderilen giriş isteği:", formData);
      
      const response = await axiosInstance.post('/api/auth/login', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      console.log("Alınan yanıt:", response);

      if (response.status === 200 && response.data.token) {
        setAuthToken(response.data.token);
        localStorage.setItem('authToken', response.data.token);
        console.log("Token bağlamda ve localStorage'da ayarlandı:", response.data.token);
        navigate('/'); // Redirect after successful login
      } else {
        setError('Geçersiz giriş bilgileri.');
      }
    } catch (error) {
      console.error('Giriş hatası:', error.response?.data || error.message);
      
      if (error.response && error.response.data && error.response.data.message) {
        const turkishMessage = mapErrorMessage(error.response.data.message);
        setError(turkishMessage);
      } else {
        setError('Giriş sırasında beklenmeyen bir hata oluştu.');
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        {/* Removed the company logo */}
        <h4 className="login-title">Yönetİcİ Gİrİşİ</h4>
        <p className="login-subtitle">ÜRÜN YÖNETİM PLATFORMU</p>

        {error && <p className="login-error">{error}</p>}

        <form className="login-form" onSubmit={handleSubmit} noValidate>
          <div className="login-text-area">
            <FontAwesomeIcon icon={faUser} className="login-icon" aria-hidden="true" />
            <input
              type="text"
              className="login-text-input"
              placeholder="Kullanıcı adı"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              required
              aria-label="Kullanıcı adı"
            />
          </div>
          <div className="login-text-area">
            <FontAwesomeIcon icon={faKey} className="login-icon" aria-hidden="true" />
            <input
              type="password"
              className="login-text-input"
              placeholder="Şifre"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
              aria-label="Şifre"
            />
          </div>
          <button type="submit" className="login-btn" disabled={isLoading}>
            {isLoading ? <span className="spinner"></span> : 'Giriş'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
