import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import './CatalogManagement.css';

const CatalogManagement = () => {
  const [catalogs, setCatalogs] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCatalogs();
  }, []);

  const fetchCatalogs = async () => {
    try {
      const { data } = await axios.get('/api/catalogs');
      // Ensure pdfUrl includes protocol
      const processedCatalogs = data.map((catalog) => {
        let { pdfUrl } = catalog;
        if (!pdfUrl.startsWith('http://') && !pdfUrl.startsWith('https://')) {
          pdfUrl = `https://${pdfUrl}`;
        }
        return { ...catalog, pdfUrl };
      });
      setCatalogs(processedCatalogs);
    } catch (err) {
      setError('Kataloglar yüklenirken bir hata oluştu.');
      console.error('Error fetching catalogs:', err);
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Bu katalogu silmek istediğinizden emin misiniz?');
    if (!confirmed) return;

    try {
      await axios.delete(`/api/catalogs/${id}`);
      setCatalogs((prevCatalogs) => prevCatalogs.filter((catalog) => catalog._id !== id));
    } catch (err) {
      setError('Katalog silinirken bir hata oluştu.');
      console.error('Error deleting catalog:', err);
    }
  };

  return (
    <div className="catalog-management-container">
      <h1>Katalog Yönetimi</h1>
      {error && <p className="message error">{error}</p>}
      {catalogs.length === 0 ? (
        <p className="no-catalog-message">Kayıtlı katalog bulunmamaktadır.</p>
      ) : (
        <ul className="catalog-list">
          {catalogs.map(({ _id, name, pdfUrl }) => (
            <CatalogItem
              key={_id}
              name={name}
              pdfUrl={pdfUrl}
              onDelete={() => handleDelete(_id)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const CatalogItem = ({ name, pdfUrl, onDelete }) => (
  <li className="catalog-item">
    <span className="catalog-management-name">{name}</span>
    <div className="catalog-actions">
      <a
        href={pdfUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="btn view-pdf"
      >
        PDF Görüntüle
      </a>
      <button onClick={onDelete} className="btn delete">
        Sil
      </button>
    </div>
  </li>
);

export default CatalogManagement;
