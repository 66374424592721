// src/utils/geoUtils.js

import * as turf from '@turf/turf';

/**
 * Finds a country feature by its name.
 * @param {Object} geoData - The GeoJSON data.
 * @param {string} countryName - The name of the country to find.
 * @returns {Object|undefined} - The country feature or undefined if not found.
 */
export const getCountryInfo = (geoData, countryName) =>
  geoData.features.find((f) => f.properties.name === countryName);

/**
 * Calculates the centroid of a given country.
 * @param {Object} geoData - The GeoJSON data.
 * @param {string} countryName - The name of the country.
 * @returns {Array<number>|null} - The [latitude, longitude] of the centroid or null if not found.
 */
export const getCountryCentroid = (geoData, countryName) => {
  const country = getCountryInfo(geoData, countryName);
  if (country) {
    const centroid = turf.centroid(country);
    const [lng, lat] = centroid.geometry.coordinates;
    return [lat, lng];
  }
  return null;
};

/**
 * Calculates centroids for all countries in the GeoJSON data.
 * @param {Object} geoData - The GeoJSON data.
 * @returns {Array<Object>} - An array of objects containing country names and their centroids.
 */
export const getAllCountryCentroids = (geoData) =>
  geoData.features.map((feature) => {
    const centroid = turf.centroid(feature);
    const [lng, lat] = centroid.geometry.coordinates;
    return {
      name: feature.properties.name,
      coordinates: [lat, lng],
    };
  });
