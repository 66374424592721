// src/components/CatalogForm.js

import React, { useState } from 'react';
import axios from '../../utils/axiosConfig';
import './CatalogForm.css';
import { FiFileText, FiUpload } from 'react-icons/fi'; // Importing icons

const CatalogForm = () => {
  const [formData, setFormData] = useState({ name: '', pdf: null });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state added

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file?.type === 'application/pdf') {
      setFormData({ ...formData, pdf: file });
      setError('');
    } else {
      setFormData({ ...formData, pdf: null });
      setError('Lütfen geçerli bir PDF dosyası yükleyin.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, pdf } = formData;

    if (!name || !pdf) {
      setError('Katalog adı ve PDF dosyası gereklidir.');
      return;
    }

    const uploadData = new FormData();
    uploadData.append('name', name);
    uploadData.append('pdf', pdf);

    try {
      setLoading(true); // Start loading
      const token = localStorage.getItem('authToken');

      await axios.post('/api/catalogs', uploadData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      setMessage('Katalog başarıyla eklendi.');
      setError('');
      setFormData({ name: '', pdf: null });
      document.getElementById('pdfInput').value = '';
    } catch (err) {
      const errorMsg = err.response?.data?.message || 'Katalog eklenirken bir hata oluştu.';
      setError(errorMsg);
      console.error('Error adding catalog:', err.response?.data || err.message);
      setMessage('');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="catalog-form-container">
      <h1>Katalog Ekle</h1>
      {message && <p className="message success">{message}</p>}
      {error && <p className="message error">{error}</p>}
      {loading && <p className="message loading">Yükleniyor...</p>}
      <form className="catalog-form" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-field">
          <label htmlFor="name">Katalog Adı</label>
          <div className="input-wrapper">
            <FiFileText className="input-icon" />
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Katalog Adını Girin"
              required
            />
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="pdfInput">Katalog PDF Dosyası</label>
          <div className="file-input-wrapper">
            <FiUpload className="file-input-icon" />
            <input
              type="file"
              id="pdfInput"
              name="pdf"
              accept="application/pdf"
              onChange={handleFileChange}
              required
            />
            <span className="file-input-text">
              {formData.pdf ? formData.pdf.name : 'Dosya Seçin...'}
            </span>
          </div>
        </div>
        <button type="submit" className="btn-submit" disabled={loading}>
          {loading ? 'Yükleniyor...' : 'Katalog Ekle'}
        </button>
      </form>
    </div>
  );
};

export default CatalogForm;
