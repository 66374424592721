// src/components/Footer.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope, faMobile } from '@fortawesome/free-solid-svg-icons';
import './Footer.css'; // Import the CSS for the footer

const Footer = () => {
  const navigate = useNavigate();

  // Scroll to top on internal link click
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  // Social media handlers
  const openFacebook = () => {
    window.open('https://www.facebook.com/krlkimya', '_blank');
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/krlkimya', '_blank');
  };

  const openLinkedIn = () => {
    window.open('https://www.linkedin.com/company/krl-kimya', '_blank');
  };

  return (
    <footer className="footer" data-aos="fade-up">
      <Container fluid className="text-light">
        <Row>
          <Col md={12} className="footer-section contact">
            <h5 onClick={() => handleNavigation('/contact')}>Bize Ulaşın</h5>
            <address>
              <p>
                <FontAwesomeIcon icon={faLocationDot} />
                <a
                  href="https://maps.app.goo.gl/Jk8ef54a4V83cGyB8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kafkas Cd. No:5, 34953 Aydınlı KOSB Tuzla/İstanbul
                </a>
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} />
                <a href="tel:+902165049470"> +90 216 504 94 70</a>
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} />
                <a href="tel:+902165049479"> +90 216 504 94 79</a>
              </p>
              <p>
                <FontAwesomeIcon icon={faMobile} />
                <a href="tel:+905495754408"> +90 549 575 44 08</a>
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} />
                <a href="mailto:info@krlkimya.com">info@krlkimya.com</a>
              </p>
            </address>
          </Col>
        </Row>
        <Row className="footer-section-wrapper">
          <Col xs={12} md={4} className="footer-section">
            <h5>Kurumsal</h5>
            <ul>
              <li>
                <a onClick={() => handleNavigation('/about')}>Hakkımızda</a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/catalog')}>Kataloglarımız</a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/documents')}>Belgelerimiz</a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/contact')}>Demo Talep Edin</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} className="footer-section">
            <h5>Ürünlerimiz</h5>
            <ul>
              <li>
                <a onClick={() => handleNavigation('/products?category=mutfak')}>Mutfak</a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/products?category=bina-bakimi')}>Bina Bakımı</a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/products?category=kisisel-hijyen')}>Kişisel Hijyen</a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/products?category=camasir')}>Çamaşır</a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/products?category=gida-hijyeni')}>
                 Gıda Hijyeni
                </a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/products?category=havuz-kimyasallari')}>Havuz Kimyasalları</a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/products?category=ozel-urunler')}>
                  Özel Ürünler
                </a>
              </li>
              <li>
                <a onClick={() => handleNavigation('/products?category=endustriyel-kimya')}>
                  Endüstriyel Kimyasallar
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} className="footer-section">
            <h5>Sosyal Medya</h5>
            <ul>
              <li onClick={openFacebook}>
                <a>Facebook</a>
              </li>
              <li onClick={openInstagram}>
                <a>Instagram</a>
              </li>
              <li onClick={openLinkedIn}>
                <a>LinkedIn</a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="footer-bottom">
          <Col md={12} className="text-center">
            <p>© {new Date().getFullYear()} KRL Kimya. All Rights Reserved by Caner Battaloglu.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
