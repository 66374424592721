// src/components/AboutUs.js

import React, { useEffect } from 'react';
import './AboutUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';

const AboutUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Whether animation should happen only once
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="about-us-container">
      {/* Who Are We Section */}
      <section className="about-us-section who-are-we-section" data-aos="fade-up">
        <h2 className="section-title">Üretim Tesisimiz</h2>
        <div className="section-content">
          <iframe
            title="Who Are We?"
            src="https://www.youtube.com/embed/h3eLcrRt9ps"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </section>

      {/* Our Mission Section */}
      <section className="about-us-section our-mission-section" data-aos="fade-right">
        <div className="mission-content">
          <h2 className="section-title">Misyonumuz</h2>
          <ul className="mission-list">
            <li data-aos="fade-up" data-aos-delay="100">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              KRL KİMYA olarak başladığımız günden bugüne kadar olduğu gibi, hedefimiz her zaman ihtiyaçları doğru bir şekilde değerlendirerek doğru ürünleri doğru müşterilere sunmak,
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              Kalitemizin ve başarımızın sürekliliğini sağlamak,
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              Sektörel yenilikleri yakından takip etmek ve en yüksek teknolojiyi kullanan firmamızı kaliteye verdiğimiz önemle tanıtmak,
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              Ürün sağladığımız iş ortaklarımız olan müşteri ve kuruluşlar ile ilişkilerimizde tutarlı, şeffaf ve karşılıklı hoşgörü çerçevesine dayanan iletişimimizi koruyarak aranan bir kurum olma özelliğimizi devam ettirmektir.
            </li>
          </ul>
        </div>
        <div className="mission-image-container">
          <img src="/images/mission-image.jpg" alt="Mission Image" loading="lazy" />
        </div>
      </section>

      {/* Our Vision Section */}
      <section className="about-us-section our-vision-section" data-aos="fade-left">
        {/* Image moved to the left */}
        <div className="vision-image-container">
          <img src="/images/vision-image.jpg" alt="Vision Image" loading="lazy" />
        </div>
        <div className="vision-content">
          <h2 className="section-title">Vizyonumuz</h2>
          <ul className="vision-list">
            <li data-aos="fade-up" data-aos-delay="100">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              Temizlik ve bakım kimyasalları sektöründe her zaman yüksek kaliteli ürünler üretmek,
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              Memnuniyeti temel ilke olarak kabul eden tecrübeli kadromuzla en iyi ürünü en hızlı şekilde sunmak,
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              Teknolojik yenilikleri takip ederek, kalite standartlarını en üst seviyede tutmak,
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              Hizmet verdiğimiz müşteri ve kuruluşların, memnuniyetinde devamlılık sağlamak,
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              <FontAwesomeIcon icon={faCheckCircle} className="icon" />
              AR-GE yatırımlarımız ile ürün kalitemizi arttırmak ve müşterilerimizin memnuniyetini sağlamak için çaba sarf ediyoruz.
            </li>
          </ul>
        </div>
      </section>

      {/* Back to Top Button */}
      <button className="back-to-top" onClick={scrollToTop} aria-label="Back to Top">
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </div>
  );
};

export default AboutUs;
