import React, { useContext, useState, useEffect, useRef } from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
  faUser,
  faArrowRightFromBracket,
  faEnvelope,
  faPhone,
  faLocationDot,
  faBars,
  faSearch,
  faTimes,
  faUtensils,
  faBuilding,
  faHandsWash,
  faTshirt,
  faSeedling,
  faSwimmingPool,
  faFlask,
  faTools,
  faPlusCircle,
  faMinusCircle,
  faUpload,
  faTrashAlt,
  faFileUpload,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../authContetxt'; // Ensure the path is correct
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../utils/axiosConfig'; // Ensure your axios configuration is correct

const Header = () => {
  const searchResultsRef = useRef(null);
  const { authToken, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  // State to manage the navigation menu's open/close status
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [openDesktopSubMenu, setOpenDesktopSubMenu] = useState(null);
  const [openMobileHeaderSubMenu, setOpenMobileHeaderSubMenu] = useState(null);

  // Separate state variables for mobile submenus
  const [isProductsSubMenuOpen, setProductsSubMenuOpen] = useState(false);
  const [isAdminSubMenuOpen, setAdminSubMenuOpen] = useState(false);

  // Function to handle clicks outside the search results
  const handleClickOutside = (event) => {
    if (
      searchResultsRef.current &&
      !searchResultsRef.current.contains(event.target)
    ) {
      setSearchResults([]);
    }
  };

  // Function to toggle the navigation menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const openFacebook = () => {
    window.open('https://www.facebook.com/krlkimya', '_blank');
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/krlkimya', '_blank');
  };

  const openLinkedIn = () => {
    window.open('https://www.linkedin.com/company/krl-kimya', '_blank');
  };

  const openContact = () => {
    navigate('/contact');
  };

  const openLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    logout();
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  // Function to fetch search results
  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`/api/products/search?query=${query}`);
      setSearchResults(response.data); // Set the search results
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]); // Clear results on error
    }
  };

  // Add event listener once on mount
  useEffect(() => {
    document.addEventListener('click', handleClickOutside); // Changed from 'mousedown' to 'click'
    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Handle search term changes
  useEffect(() => {
    if (searchTerm.trim() !== '') {
      const delayDebounceFn = setTimeout(() => {
        fetchSearchResults(searchTerm);
      }, 300); // Debounce for 300ms
      return () => clearTimeout(delayDebounceFn);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleProductClick = (productId, event) => {
    event.stopPropagation(); // Prevent the click event from propagating
    navigate(`/products/${productId}`); // Navigate to the product detail page
    setSearchResults([]); // Clear search results
    setSearchTerm(''); // Clear search term
    setMobileMenuOpen(false); // Close the mobile menu if it's open
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
  };

  // Helper function to construct image URL
  const constructImageUrl = (baseFilename, size = '320w', format = 'png') => {
    return `https://krl-kimya.fra1.digitaloceanspaces.com/images/${baseFilename}-${size}.${format}`;
  };

  return (
    <header className="header-top-two">
      {/* Header Top */}
      <div className="header-top header-top-two">
        <div className="container header-top-container">
          <div className="header-top-left">
            <span onClick={openFacebook}>
              <FontAwesomeIcon icon={faFacebook} className="header-icon" />
              <span className="social-text">facebook</span>
            </span>
            <span onClick={openInstagram}>
              <FontAwesomeIcon icon={faInstagram} className="header-icon" />
              <span className="social-text">instagram</span>
            </span>
            <span onClick={openLinkedIn}>
              <FontAwesomeIcon icon={faLinkedin} className="header-icon" />
              <span className="social-text">linkedIn</span>
            </span>
          </div>
          <div className="header-top-right">
            <button className="demo-button" onClick={openContact}>
              Demo talep et
            </button>
            {authToken ? (
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className="login-icon"
                onClick={handleLogout}
                title="Logout"
              />
            ) : (
              <FontAwesomeIcon
                icon={faUser}
                className="login-icon"
                onClick={openLogin}
                title="Login"
              />
            )}
          </div>
        </div>
      </div>

      {/* Header Logo and Contact Info */}
      <div className="header-logo-area">
        <div className="container header-logo-container">
          <div className="logo">
            <Link to="/">
              <img src="/images/krl_logo.png" alt="Logo" />
            </Link>
          </div>
          <div className="header-contact-info">
            <div className="contact-info-item">
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </div>
              <div className="content">
                <h4 className="title">Bize Yazın</h4>
                <a href="mailto:info@krlkimya.com">info@krlkimya.com</a>
              </div>
            </div>
            <div className="contact-info-item">
              <div className="icon">
                <FontAwesomeIcon icon={faPhone} size="2x" />
              </div>
              <div className="content">
                <h4 className="title">Telefon Numaramız</h4>
                <a href="tel:+902165049470">+90 216 504 94 70</a>
              </div>
            </div>
            <div className="contact-info-item">
              <div className="icon">
                <FontAwesomeIcon icon={faLocationDot} size="2x" />
              </div>
              <div className="content">
                <h4 className="title">KRL Kimya</h4>
                <a
                  href="https://maps.app.goo.gl/Jk8ef54a4V83cGyB8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tuzla Kimya OSB, İstanbul
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation */}
      <div className="menu-area">
        <div className="container menu-container">
          <nav className="menu-nav">
            {/* Mobile Header */}
            <div className="mobile-header">
              <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                <FontAwesomeIcon icon={faBars} size="lg" />
              </div>
              <div className="mobile-menu-items">
                <Link to="/about" className="mobile-menu-link">
                  Hakkımızda
                </Link>
                <div
                  className="mobile-menu-link menu-item-has-children"
                  style={{ position: 'relative' }}
                >
                  <span
                    className="menu-title"
                    onClick={() => {
                      if (openMobileHeaderSubMenu === 'productsMobileHeader') {
                        setOpenMobileHeaderSubMenu(null);
                      } else {
                        setOpenMobileHeaderSubMenu('productsMobileHeader');
                      }
                    }}
                  >
                    Ürünlerimiz
                  </span>
                  {openMobileHeaderSubMenu === 'productsMobileHeader' && (
                    <ul className="sub-menu sub-menu-mobile-header">
                      <li>
                        <Link to="/products?category=mutfak">
                          <FontAwesomeIcon
                            icon={faUtensils}
                            className="submenu-icon"
                          />
                          Mutfak
                        </Link>
                      </li>
                      <li>
                        <Link to="/products?category=bina-bakimi">
                          <FontAwesomeIcon
                            icon={faBuilding}
                            className="submenu-icon"
                          />
                          Bina Bakımı
                        </Link>
                      </li>
                      <li>
                        <Link to="/products?category=kisisel-hijyen">
                          <FontAwesomeIcon
                            icon={faHandsWash}
                            className="submenu-icon"
                          />
                          Kişisel Hijyen
                        </Link>
                      </li>
                      <li>
                        <Link to="/products?category=camasir">
                          <FontAwesomeIcon
                            icon={faTshirt}
                            className="submenu-icon"
                          />
                          Çamaşır
                        </Link>
                      </li>
                      <li>
                        <Link to="/products?category=gida-hijyeni">
                          <FontAwesomeIcon
                            icon={faSeedling}
                            className="submenu-icon"
                          />
                          Gıda Hijyeni
                        </Link>
                      </li>
                      <li>
                        <Link to="/products?category=havuz-kimyasallari">
                          <FontAwesomeIcon
                            icon={faSwimmingPool}
                            className="submenu-icon"
                          />
                          Havuz Kimyasalları
                        </Link>
                      </li>
                      <li>
                        <Link to="/products?category=ozel-urunler">
                          <FontAwesomeIcon
                            icon={faFlask}
                            className="submenu-icon"
                          />
                          Özel Ürünler
                        </Link>
                      </li>
                      <li>
                        <Link to="/products?category=endustriyel-kimya">
                          <FontAwesomeIcon
                            icon={faTools}
                            className="submenu-icon"
                          />
                          Endüstriyel Kimyasallar
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
                <Link to="/contact" className="mobile-menu-link">
                  İletişim
                </Link>
              </div>
            </div>

            {/* Mobile Menu Overlay */}
            {isMobileMenuOpen && (
              <>
                <div
                  className="mobile-menu-overlay"
                  onClick={toggleMobileMenu}
                ></div>
                <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
                  {/* Close Icon */}
                  <div className="mobile-menu-close" onClick={toggleMobileMenu}>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                  {/* Mobile Search Bar */}
                  <div className="mobile-search-container">
                    <div className="search-container-mobile">
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="search-icon-mobile"
                      />
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Ürün ara..."
                        className="search-input-mobile"
                      />
                      {searchTerm && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="cancel-icon-mobile"
                          onClick={clearSearch}
                        />
                      )}
                    </div>
                    {/* Display Search Results */}
                    {searchResults.length > 0 && (
                      <div
                        className="search-results-mobile"
                        ref={searchResultsRef}
                      >
                        {searchResults.map((product) => {
                          // Handle cases where product.images[0] is a full URL or a base filename
                          let imageUrl = '';
                          const imageEntry = product.images[0];

                          if (imageEntry.startsWith('http')) {
                            imageUrl = imageEntry;
                          } else {
                            imageUrl = constructImageUrl(imageEntry);
                          }

                          return (
                            <div
                              key={product._id}
                              className="search-result-item"
                              onClick={(e) => handleProductClick(product._id, e)}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                src={imageUrl}
                                alt={product.title}
                                className="search-result-image"
                              />
                              <div>
                                <h4>{product.title}</h4>
                                <p>{product.description}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {/* Mobile Menu Items */}
                  <ul className="mobile-navigation">
                    <li>
                      <Link to="/" onClick={toggleMobileMenu}>
                        Ana Sayfa
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={toggleMobileMenu}>
                        Hakkımızda
                      </Link>
                    </li>
                    <li
                      className={`menu-item-has-children ${isProductsSubMenuOpen ? 'open' : ''
                        }`}
                    >
                      <span
                        className="menu-title"
                        onClick={() => {
                          setProductsSubMenuOpen(!isProductsSubMenuOpen);
                        }}
                      >
                        Ürünlerimiz
                      </span>
                      {isProductsSubMenuOpen && (
                        <ul className="sub-menu">
                          <li>
                            <Link
                              to="/products?category=mutfak"
                              onClick={toggleMobileMenu}
                            >
                              <FontAwesomeIcon
                                icon={faUtensils}
                                className="submenu-icon"
                              />
                              Mutfak
                            </Link>
                          </li>
                          <li>
                            <Link to="/products?category=bina-bakimi">
                              <FontAwesomeIcon
                                icon={faBuilding}
                                className="submenu-icon"
                              />
                              Bina Bakımı
                            </Link>
                          </li>
                          <li>
                            <Link to="/products?category=kisisel-hijyen">
                              <FontAwesomeIcon
                                icon={faHandsWash}
                                className="submenu-icon"
                              />
                              Kişisel Hijyen
                            </Link>
                          </li>
                          <li>
                            <Link to="/products?category=camasir">
                              <FontAwesomeIcon
                                icon={faTshirt}
                                className="submenu-icon"
                              />
                              Çamaşır
                            </Link>
                          </li>
                          <li>
                            <Link to="/products?category=gida-hijyeni">
                              <FontAwesomeIcon
                                icon={faSeedling}
                                className="submenu-icon"
                              />
                              Gıda Hijyeni
                            </Link>
                          </li>
                          <li>
                            <Link to="/products?category=havuz-kimyasallari">
                              <FontAwesomeIcon
                                icon={faSwimmingPool}
                                className="submenu-icon"
                              />
                              Havuz Kimyasalları
                            </Link>
                          </li>
                          <li>
                            <Link to="/products?category=ozel-urunler">
                              <FontAwesomeIcon
                                icon={faFlask}
                                className="submenu-icon"
                              />
                              Özel Ürünler
                            </Link>
                          </li>
                          <li>
                            <Link to="/products?category=endustriyel-kimya">
                              <FontAwesomeIcon
                                icon={faTools}
                                className="submenu-icon"
                              />
                              Endüstriyel Kimyasallar
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <Link to="/catalog" onClick={toggleMobileMenu}>
                        Kataloglarımız
                      </Link>
                    </li>
                    <li>
                      <Link to="/documents" onClick={toggleMobileMenu}>
                        Belgelerimiz
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" onClick={toggleMobileMenu}>
                        İletişim
                      </Link>
                    </li>
                    {authToken && (
                      <li
                        className={`menu-item-has-children ${isAdminSubMenuOpen ? 'open' : ''
                          }`}
                      >
                        <span
                          className="menu-title"
                          onClick={() => {
                            setAdminSubMenuOpen(!isAdminSubMenuOpen);
                          }}
                        >
                          Ürün Yönetim Platformu
                        </span>
                        {isAdminSubMenuOpen && (
                          <ul className="sub-menu">
                            <li>
                              <Link
                                to="/add-product"
                                onClick={toggleMobileMenu}
                              >
                                <FontAwesomeIcon
                                  icon={faPlusCircle}
                                  className="submenu-icon"
                                />
                                Ürün Ekle
                              </Link>
                            </li>
                            <li>
                              <Link to="/manage-products">
                                <FontAwesomeIcon
                                  icon={faMinusCircle}
                                  className="submenu-icon"
                                />
                                Ürün Sil
                              </Link>
                            </li>
                            <li>
                              <Link to="/add-catalog">
                                <FontAwesomeIcon
                                  icon={faFileUpload}
                                  className="submenu-icon"
                                />
                                Katalog Ekle
                              </Link>
                            </li>
                            <li>
                              <Link to="/manage-catalogs">
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="submenu-icon"
                                />
                                Katalog Sil
                              </Link>
                            </li>
                            <li>
                              <Link to="/add-documents">
                                <FontAwesomeIcon
                                  icon={faFileUpload}
                                  className="submenu-icon"
                                />
                                Belge Ekle
                              </Link>
                            </li>
                            <li>
                              <Link to="/manage-documents">
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="submenu-icon"
                                />
                                Belge Sil
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                    )}
                  </ul>
                </div>
              </>
            )}

            {/* Desktop Navigation */}
            <ul className="navigation">
              <li>
                <Link to="/">Ana Sayfa</Link>
              </li>
              <li>
                <Link to="/about">Hakkımızda</Link>
              </li>
              <li
                className="menu-item-has-children"
                onMouseEnter={() => setOpenDesktopSubMenu('products')}
                onMouseLeave={() => setOpenDesktopSubMenu(null)}
              >
                <span className="menu-title">Ürünlerimiz</span>
                {openDesktopSubMenu === 'products' && (
                  <ul className="sub-menu sub-menu-desktop">
                    <li>
                      <Link to="/products?category=mutfak">
                        <FontAwesomeIcon
                          icon={faUtensils}
                          className="submenu-icon"
                        />
                        Mutfak
                      </Link>
                    </li>
                    <li>
                      <Link to="/products?category=bina-bakimi">
                        <FontAwesomeIcon
                          icon={faBuilding}
                          className="submenu-icon"
                        />
                        Bina Bakımı
                      </Link>
                    </li>
                    <li>
                      <Link to="/products?category=kisisel-hijyen">
                        <FontAwesomeIcon
                          icon={faHandsWash}
                          className="submenu-icon"
                        />
                        Kişisel Hijyen
                      </Link>
                    </li>
                    <li>
                      <Link to="/products?category=camasir">
                        <FontAwesomeIcon
                          icon={faTshirt}
                          className="submenu-icon"
                        />
                        Çamaşır
                      </Link>
                    </li>
                    <li>
                      <Link to="/products?category=gida-hijyeni">
                        <FontAwesomeIcon
                          icon={faSeedling}
                          className="submenu-icon"
                        />
                        Gıda Hijyeni
                      </Link>
                    </li>
                    <li>
                      <Link to="/products?category=havuz-kimyasallari">
                        <FontAwesomeIcon
                          icon={faSwimmingPool}
                          className="submenu-icon"
                        />
                        Havuz Kimyasalları
                      </Link>
                    </li>
                    <li>
                      <Link to="/products?category=ozel-urunler">
                        <FontAwesomeIcon
                          icon={faFlask}
                          className="submenu-icon"
                        />
                        Özel Ürünler
                      </Link>
                    </li>
                    <li>
                      <Link to="/products?category=endustriyel-kimya">
                        <FontAwesomeIcon
                          icon={faTools}
                          className="submenu-icon"
                        />
                        Endüstriyel Kimyasallar
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link to="/catalog">Kataloglarımız</Link>
              </li>
              <li>
                <Link to="/documents">Belgelerimiz</Link>
              </li>
              <li>
                <Link to="/contact">İletişim</Link>
              </li>
              {authToken && (
                <li
                  className="menu-item-has-children"
                  onMouseEnter={() => setOpenDesktopSubMenu('admin')}
                  onMouseLeave={() => setOpenDesktopSubMenu(null)}
                >
                  <span className="menu-title">Ürün Yönetim Platformu</span>
                  {openDesktopSubMenu === 'admin' && (
                    <ul className="sub-menu sub-menu-desktop">
                      <li>
                        <Link to="/add-product">
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="submenu-icon"
                          />
                          Ürün Ekle
                        </Link>
                      </li>
                      <li>
                        <Link to="/manage-products">
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="submenu-icon"
                          />
                          Ürün Sil
                        </Link>
                      </li>
                      <li>
                        <Link to="/add-catalog">
                          <FontAwesomeIcon
                            icon={faUpload}
                            className="submenu-icon"
                          />
                          Katalog Ekle
                        </Link>
                      </li>
                      <li>
                        <Link to="/manage-catalogs">
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="submenu-icon"
                          />
                          Katalog Sil
                        </Link>
                      </li>
                      <li>
                        <Link to="/add-documents">
                          <FontAwesomeIcon
                            icon={faFileUpload}
                            className="submenu-icon"
                          />
                          Belge Ekle
                        </Link>
                      </li>
                      <li>
                        <Link to="/manage-documents">
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="submenu-icon"
                          />
                          Belge Sil
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              )}
            </ul>

            {/* Desktop Search Container */}
            <div className="search-container">
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Ürün ara..."
              />

              {/* Display Search Results */}
              {searchResults.length > 0 && (
                <div className="search-results" ref={searchResultsRef}>
                  {searchResults.map((product) => {
                    // Handle cases where product.images[0] is a full URL or a base filename
                    let imageUrl = '';
                    const imageEntry = product.images[0];

                    if (imageEntry.startsWith('http')) {
                      imageUrl = imageEntry;
                    } else {
                      imageUrl = constructImageUrl(imageEntry);
                    }

                    return (
                      <div
                        key={product._id}
                        className="search-result-item"
                        onClick={(e) => handleProductClick(product._id, e)}
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          src={imageUrl}
                          alt={product.title}
                          className="search-result-image"
                        />
                        <div>
                          <h4>{product.title}</h4>
                          <p>{product.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>

      {showPopup && (
        <div className="logout-popup">Hesabınızdan çıkış yaptınız.</div>
      )}
    </header>
  );
};

export default Header;
