// src/authContext.js

import React, { createContext, useState } from 'react';

// AuthContext oluşturma
export const AuthContext = createContext();

// AuthProvider bileşeni
export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() => {
    return localStorage.getItem('authToken') || null;
  });

  // Logout fonksiyonu
  const logout = () => {
    setAuthToken(null); // AuthContext içindeki token'ı temizle
    localStorage.removeItem('authToken'); // localStorage'daki token'ı temizle
    window.location.href = '/login'; // Login sayfasına yönlendir
  };

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
