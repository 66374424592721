// src/utils/axiosConfig.js

import axios from 'axios';

const baseURL = 
  process.env.NODE_ENV === 'production'
    ? 'http://167.99.138.77:3001' // Production API URL
    : 'http://localhost:3001'; // Development API URL


// Axios instance'ınızı backend sunucunuzun doğru URL'si ile yapılandırın
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true, // If your backend requires cookies or credentials
});
// İstek öncesi token ekleme
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken'); // Token'ı localStorage'dan al
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Authorization header'ına ekle
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
