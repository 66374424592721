import React from 'react';
import CategoryPage from './CategoryPage'; // Assuming CategoryPage is imported correctly
import { products } from '../../shared/ProductList';
// src/pages/Products/Products.js


const Products= () => {
  // Sample products array
  
  return <CategoryPage products={products} />;
};

export default Products;
