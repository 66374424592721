// src/pages/Document/Documents.js

import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import './Documents.css';

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const { data } = await axios.get('/api/documents');
      setDocuments(data);
    } catch (err) {
      console.error('Error fetching documents:', err);
      setError('Belgeler yüklenirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  if (loading) {
    return (
      <div className="documents-container">
        <h1>Belgelerimiz</h1>
        <p>Yükleniyor...</p>
      </div>
    );
  }

  return (
    <div className="documents-container">
      <h1>Belgelerimiz</h1>

      {/* Error Message */}
      {error && <p className="error-message">{error}</p>}

      {/* Documents Grid */}
      {documents.length === 0 ? (
        <p className="no-documents">Kayıtlı belge bulunmamaktadır.</p>
      ) : (
        <div className="documents-grid">
          {documents.map(({ _id, name, logoUrl, pdfUrl }) => (
            <div
              key={_id}
              className="document-card"
              onClick={() => handleCardClick(pdfUrl)}
              role="button"
              tabIndex="0"
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleCardClick(pdfUrl);
              }}
              aria-label={`Belge: ${name}`}
            >
              <div className="document-logo">
                <img
                  src={logoUrl}
                  alt={`${name} Logo`}
                  onError={(e) => {
                    e.target.src = '/images/fallback-logo.png';
                  }}
                />
              </div>
              <div className="document-name">
                <h3>{name}</h3>
              </div>
              <div className="document-pdf">
                <a
                  href={pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()} // Prevent card click when clicking link
                  aria-label={`${name} PDF'ini aç`}
                >
                  PDF'i Aç
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Documents;
