// src/components/ProductDetailPage/ProductDetailPage.js

import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Image,
  Tab,
  Nav,
  Breadcrumb,
  Modal,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { FaSearchPlus, FaSearchMinus, FaTimes } from 'react-icons/fa';
import axios from '../../utils/axiosConfig';
import './ProductDetailPage.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { extractYouTubeVideoID } from '../../utils/youtubeUtils'; // Ensure correct import

const ProductDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialTab = query.get('tab') || 'details';

  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showZoomModal, setShowZoomModal] = useState(false);
  const [zoomScale, setZoomScale] = useState(1); // Zoom scale for image

  const thumbnails = useMemo(
    () =>
      product?.images.filter((url) =>
        url.toLowerCase().includes('-320w.png')
      ) || [],
    [product]
  );

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/products/${id}`);
        const productData = response.data;
        setProduct(productData);
        const initialMainImage =
          productData.images.find((url) =>
            url.toLowerCase().includes('-1280w.png')
          ) || productData.images[0];
        setMainImage(initialMainImage);
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [id]);

  const handleTabSelect = (selectedKey) => {
    navigate(`${location.pathname}?tab=${selectedKey}`, { replace: true });
  };

  const handleImageClick = () => {
    setShowZoomModal(true);
  };

  const handleZoomModalClose = () => {
    setShowZoomModal(false);
    setZoomScale(1); // Reset zoom scale on close
  };

  const zoomIn = () =>
    setZoomScale((prev) => Math.min(prev + 0.25, 3)); // Max zoom level
  const zoomOut = () =>
    setZoomScale((prev) => Math.max(prev - 0.25, 1)); // Min zoom level

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  if (!product) {
    return <div className="error-message">Ürün bulunamadı.</div>;
  }

  const {
    title,
    description,
    content = [],
    weight = [],
    packSize = [],
    msds,
    tds,
    youtubeLink,
    category,
  } = product;

  // Define the category mapping for display names
  const categoryDisplayNames = {
    'bina-bakimi': 'Bina Bakımı',
    'endustriyel-kimya': 'Endüstriyel Kimyasallar',
    'kisisel-hijyen': 'Kişisel Hijyen',
    'camasir': 'Çamaşır',
    'havuz-kimyasallari': 'Havuz Kimyasalları',
    'gida-hijyeni': 'Gıda Hijyeni',
    'ozel-urunler': 'Özel Ürünler',
    'mutfak': 'Mutfak',
  };

  const displayCategory = categoryDisplayNames[category] || category;

  // Extract YouTube Video ID
  const videoId = extractYouTubeVideoID(youtubeLink);
  const hasDemo = videoId !== null;
  const videoSrc = hasDemo
    ? `https://www.youtube.com/embed/${videoId}`
    : '';

  // Debugging: Log the extracted video ID and src
  console.log('YouTube Video ID:', videoId);
  console.log('Video Source:', videoSrc);

  return (
    <Container fluid className="product-detail-page">
      {/* Breadcrumb */}
      <Breadcrumb className="custom-breadcrumb">
        <Breadcrumb.Item href="/">Ana Sayfa</Breadcrumb.Item>
        <Breadcrumb.Item href={`/products?category=${category}`}>
          {displayCategory}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb>

      {/* Main Content Container */}
      <Row className="content-container">
        {/* Thumbnail Sidebar */}
        <Col xs={12} md={2} className="thumbnail-sidebar">
          {thumbnails.map((thumb, idx) => (
            <div
              key={idx}
              className={`thumbnail-wrapper ${
                mainImage === thumb.replace('-320w.png', '-1280w.png')
                  ? 'selected'
                  : ''
              }`}
              onClick={() =>
                setMainImage(
                  thumb.replace('-320w.png', '-1280w.png')
                )
              }
            >
              <Image
                src={thumb} // Full URL already provided by backend
                alt={`${title} Thumbnail ${idx + 1}`}
                className="thumbnail-image"
                loading="lazy"
              />
            </div>
          ))}
        </Col>

        {/* Main Image */}
        <Col xs={12} md={5} className="main-image-container">
          <div onClick={handleImageClick}>
            <Image src={mainImage} alt={title} className="main-image" />
          </div>
        </Col>

        {/* Product Information */}
        <Col xs={12} md={5} className="product-info-section">
          <p className="product-category">{displayCategory}</p>
          <h1 className="product-detail-title">{title}</h1>
          <p className="product-description">{description}</p>

          {/* Tabs for Details */}
          <Tab.Container
            activeKey={initialTab}
            onSelect={handleTabSelect}
          >
            <Nav className="product-tabs">
              <Nav.Item>
                <Nav.Link eventKey="details">Detaylar</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="paket-boyutu">
                  Paket Boyutu
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="downloads">İndirmeler</Nav.Link>
              </Nav.Item>
              {hasDemo && (
                <Nav.Item>
                  <Nav.Link eventKey="demo">Demo</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="details">
                <ul className="product-details">
                  {content.map((detail, i) => (
                    <li key={i}>
                      {detail.replace(/^-+\s*/, '')}
                    </li>
                  ))}
                </ul>
              </Tab.Pane>
              <Tab.Pane eventKey="paket-boyutu">
                <div className="packet-sizes">
                  {weight.map((w, i) => (
                    <div key={i} className="packet-card">
                      <div className="packet-weight">
                        <strong>Ağırlık:</strong> {w}
                      </div>
                      <div className="packet-dimensions">
                        <strong>Paket Boyutu:</strong> {packSize[i]}
                      </div>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="downloads">
                <div className="download-buttons">
                  {msds && (
                    <a
                      href={msds} // Full URL provided by backend
                      target="_blank"
                      rel="noopener noreferrer"
                      className="download-button"
                    >
                      <i className="fas fa-file-download"></i> MSDS İndir
                    </a>
                  )}
                  {tds && (
                    <a
                      href={tds} // Full URL provided by backend
                      target="_blank"
                      rel="noopener noreferrer"
                      className="download-button"
                    >
                      <i className="fas fa-file-download"></i> TDS İndir
                    </a>
                  )}
                </div>
              </Tab.Pane>
              {hasDemo && (
                <Tab.Pane eventKey="demo">
                  <div className="video-container">
                    <div className="video-responsive">
                      <iframe
                        src={videoSrc}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>

      {/* Zoom Modal */}
      <Modal
        show={showZoomModal}
        onHide={handleZoomModalClose}
        size="lg"
        centered
      >
        <Modal.Body
          className="text-center"
          style={{ position: 'relative' }}
        >
          <img
            src={mainImage} // Full URL provided by backend
            alt={title}
            style={{
              transform: `scale(${zoomScale})`,
              transition: 'transform 0.3s',
            }}
            className="img-fluid"
          />
          {/* Zoom Controls - Stacked vertically on the right */}
          <Button
            variant="light"
            className="zoom-control zoom-in"
            onClick={zoomIn}
          >
            <FaSearchPlus color="#007cfb" />
          </Button>
          <Button
            variant="light"
            className="zoom-control zoom-out"
            onClick={zoomOut}
          >
            <FaSearchMinus color="#007cfb" />
          </Button>
          <Button
            variant="light"
            className="zoom-control close-zoom"
            onClick={handleZoomModalClose}
          >
            <FaTimes color="#007cfb" />
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ProductDetailPage;
