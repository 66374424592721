// src/App.js

import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// In your main JavaScript file (e.g., index.js or App.js)
import '@fortawesome/fontawesome-free/css/all.min.css';


import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import AboutUs from './components/AboutUs/AboutUs';
import Products from './pages/Products/Products';
import ProductDetailPage from './pages/Products/ProductDetailPage';
import Catalog from './pages/Catalog/Catalog';
import CatalogForm from './pages/Catalog/CatalogForm';
import CatalogManagement from './pages/Catalog/CatalogManagement';
import Contact from './components/Contact/Contact';
import Login from './pages/Login/Login';
import ProductForm from './pages/ProductManagement/ProductForm';
import ProductManagement from './pages/ProductManagement/ProductManagement';
import Documents from './pages/Document/Documents'; // New Documents main page
import DocumentsForm from './pages/Document/DocumentsForm'; // New DocumentsForm page
import DocumentsManagement from './pages/Document/DocumentsManagement'; // New DocumentsManagement page
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './authContetxt'; // Ensure correct file path
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


const App = () => {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    AOS.refresh(); // Refresh AOS on route change
  }, [location]);

  return (
    <AuthProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<ProductDetailPage />} />
        <Route path="/catalog" element={<Catalog/>} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/add-product" element={<ProductForm />} />
        <Route path="/manage-products" element={<PrivateRoute><ProductManagement /></PrivateRoute>} />
        <Route path="/add-catalog" element={<CatalogForm />} />
        <Route path="/documents" element={<Documents />} /> {/* Main Documents Page */}
        <Route path="/add-documents" element={<DocumentsForm />} /> {/* Add Documents Page */}
        <Route path="/manage-documents" element={<DocumentsManagement />} /> {/* Manage Documents Page */}
        <Route path="/manage-catalogs" element={<PrivateRoute><CatalogManagement /></PrivateRoute>} />
        {/* Optionally, add a 404 Not Found route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </AuthProvider>
  );
};

// Optional: Create a NotFound component for unmatched routes
const NotFound = () => (
  <div style={{ padding: '50px', textAlign: 'center' }}>
    <h1>404 - Sayfa Bulunamadı</h1>
    <p>Üzgünüz, aradığınız sayfa mevcut değil.</p>
  </div>
);

export default App;
